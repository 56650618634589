import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import './UsersRanking.css';

const API_URL = 'https://api.clicktoinfinity.com' || process.env.REACT_APP_API_URL || 'http://localhost:5001';

function UsersRanking() {
  const [rankingData, setRankingData] = useState({ top10: [], userPosition: null });
  const { user, anonymousId, isAuthenticated } = useAuth();
  const [error, setError] = useState(null);

  const fetchUsersRanking = useCallback(async () => {
    try {
      const params = isAuthenticated ? { userId: user.id } : { anonymousId };
      const response = await axios.get(`${API_URL}/api/clicks/users-ranking`, { params });
      setRankingData(response.data);
    } catch (error) {
      console.error('Error fetching users ranking:', error);
      setError('Failed to load user rankings. Please try again later.');
    }
  }, [isAuthenticated, user, anonymousId]);

  useEffect(() => {
    fetchUsersRanking();
    const intervalId = setInterval(fetchUsersRanking, 5000);
    return () => clearInterval(intervalId);
  }, [fetchUsersRanking]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  const getRankStyle = (index) => {
    switch(index) {
      case 0: return 'gold-rank';
      case 1: return 'silver-rank';
      case 2: return 'bronze-rank';
      default: return '';
    }
  };

  const getRankEmoji = (index) => {
    switch(index) {
      case 0: return '🥇';
      case 1: return '🥈';
      case 2: return '🥉';
      default: return index + 1;
    }
  };

  return (
    <div className="users-ranking">
      <h2>User Ranking</h2>
      <table className="users-ranking-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>User</th>
            <th>Clicks</th>
          </tr>
        </thead>
        <tbody>
          {rankingData.top10.map((user, index) => (
            <tr key={user.id} className={getRankStyle(index)}>
              <td>{getRankEmoji(index)}</td>
              <td>{user.username}</td>
              <td>{user.clicks}</td>
            </tr>
          ))}
          {rankingData.userPosition && rankingData.userPosition.position > 10 && (
            <tr className="current-user-row">
              <td>{rankingData.userPosition.position}</td>
              <td>{rankingData.userPosition.username}</td>
              <td>{rankingData.userPosition.clicks}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default UsersRanking;