import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from '../axiosConfig';

function SettingsCard() {
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');

  const checkPasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]+/)) strength++;
    if (password.match(/[A-Z]+/)) strength++;
    if (password.match(/[0-9]+/)) strength++;
    if (password.match(/[$@#&!]+/)) strength++;

    switch (strength) {
      case 0:
      case 1:
        setPasswordStrength('Weak');
        break;
      case 2:
      case 3:
        setPasswordStrength('Medium');
        break;
      case 4:
        setPasswordStrength('Strong');
        break;
      case 5:
        setPasswordStrength('Very Strong');
        break;
      default:
        setPasswordStrength('');
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
  
    if (newPassword !== confirmPassword) {
      return setError('New passwords do not match');
    }
  
    if (newPassword.length < 8 || !newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
      return setError('Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character');
    }
  
    try {
      await axios.post('/api/user/change-password', {
        currentPassword,
        newPassword
      });
      setSuccess('Password changed successfully');
      setShowPasswordForm(false);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError('Failed to change password. Please check your current password and try again.');
    }
  };

  const getPasswordStrengthDots = () => {
    switch (passwordStrength) {
      case 'Weak':
        return <span className="password-dots weak">⏺⏺</span>;
      case 'Medium':
        return <span className="password-dots medium">⏺⏺⏺</span>;
      case 'Strong':
        return <span className="password-dots strong">⏺⏺⏺⏺</span>;
      case 'Very Strong':
        return <span className="password-dots very-strong">⏺⏺⏺⏺⏺</span>;
      default:
        return null;
    }
  };

  return (
    <div className="account-card">
      <h2>Settings</h2>
      {!showPasswordForm ? (
        <button onClick={() => setShowPasswordForm(true)} className="button">Change Password</button>
      ) : (
        <form onSubmit={handlePasswordChange} className="auth-form">
          <div className="form-group">
            <label htmlFor="currentPassword">Current Password:</label>
            <input
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">New Password:</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                checkPasswordStrength(e.target.value);
              }}
              required
            />
          </div>
          {passwordStrength && (
            <div className="password-strength">
              <span>{passwordStrength} Password</span> {getPasswordStrengthDots()}
            </div>
          )}
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm New Password:</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="error">{error}</p>}
          {success && <p className="success">{success}</p>}
          <button type="submit" className="button">Change Password</button>
          <button type="button" onClick={() => setShowPasswordForm(false)} className="button secondary">Cancel</button>
        </form>
      )}
    </div>
  );
}

export default SettingsCard;