import React, { useState } from 'react';
import UsersRanking from './UsersRanking';
import CountryRanking from './CountryRanking';
import './RankingTabs.css';

function RankingTabs() {
  const [activeTab, setActiveTab] = useState('users');

  return (
    <div className="ranking-tabs">
      <div className="tab-buttons">
        <button 
          className={`tab-button ${activeTab === 'users' ? 'active' : ''}`}
          onClick={() => setActiveTab('users')}
        >
          Users Ranking
        </button>
        <button 
          className={`tab-button ${activeTab === 'countries' ? 'active' : ''}`}
          onClick={() => setActiveTab('countries')}
        >
          Country Ranking
        </button>
      </div>
      <div className="tab-content">
        {activeTab === 'users' ? <UsersRanking /> : <CountryRanking />}
      </div>
    </div>
  );
}

export default RankingTabs;