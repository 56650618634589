// components/DeleteAccountCard.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from '../axiosConfig';

function DeleteAccountCard() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    try {
      await axios.delete('/api/user/delete-account');
      logout();
      navigate('/');
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  return (
    <div className="account-card delete-account-card">
      <h2>Delete Account</h2>
      {!showConfirmation ? (
        <button onClick={() => setShowConfirmation(true)} className="button delete-button">
          Delete Account
        </button>
      ) : (
        <div>
          <p>Make your last click to infinity if you wish to delete your account.</p>
          <p>Are you sure you want to delete your account? This action cannot be undone.</p>
          <div className="button-container">
            <button onClick={handleDeleteAccount} className="button delete-button">
              Yes, Delete My Account
            </button>
            <button onClick={() => setShowConfirmation(false)} className="button secondary">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeleteAccountCard;