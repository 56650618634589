import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from '../axiosConfig';
import { normalizeCountryName, getCountryList } from '../countryMapping';
import countries from 'i18n-iso-countries';

 countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

function UserInfoCard() {
  const { user, fetchUserInfo, updateUser } = useAuth();
  const [isEditingNickname, setIsEditingNickname] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingCountry, setIsEditingCountry] = useState(false);
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const countryOptions = Object.entries(countries.getNames("en")).map(([code, name]) => ({
    value: name,
    label: name
  }));

  useEffect(() => {
    if (user) {
      setNickname(user.username || '');
      setEmail(user.email || '');
      setCountry(user.country || '');
    }
  }, [user]);

  const handleSubmitNickname = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const response = await axios.put('/api/user/update', { username: nickname });
      updateUser(response.data);
      setIsEditingNickname(false);
      setSuccess('Nickname updated successfully');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update nickname. Please try again.');
    }
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const response = await axios.put('/api/user/update', { email });
      updateUser(response.data);
      setIsEditingEmail(false);
      setSuccess('Email updated successfully');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update email. Please try again.');
    }
  };

  const handleSubmitCountry = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const normalizedCountry = normalizeCountryName(country);
      const response = await axios.put('/api/user/update', { country: normalizedCountry });
      fetchUserInfo();
      setIsEditingCountry(false);
      setSuccess('Country updated successfully');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update country. Please try again.');
    }
  };

  return (
    <div className="account-card">
      <h2>User Information</h2>
      <div className="info-field">
        <strong>Nickname:</strong>
        {isEditingNickname ? (
          <form onSubmit={handleSubmitNickname} className="inline-form">
            <input
              type="text"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              required
              maxLength={20}
            />
            <button type="submit" className="button small">Save</button>
            <button type="button" onClick={() => setIsEditingNickname(false)} className="button small secondary">Cancel</button>
          </form>
        ) : (
          <>
            {user?.username || 'Not set'}
            <button onClick={() => setIsEditingNickname(true)} className="button small">Edit</button>
          </>
        )}
      </div>
      <div className="info-field">
        <strong>Email:</strong>
        {isEditingEmail ? (
          <form onSubmit={handleSubmitEmail} className="inline-form">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="button small">Save</button>
            <button type="button" onClick={() => setIsEditingEmail(false)} className="button small secondary">Cancel</button>
          </form>
        ) : (
          <>
            {user?.email || 'Not set'}
            <button onClick={() => setIsEditingEmail(true)} className="button small">Edit</button>
          </>
        )}
      </div>
      <div className="info-field">
        <strong>Country:</strong>
        {isEditingCountry ? (
          <form onSubmit={handleSubmitCountry} className="inline-form">
            <select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
            >
              <option value="">Select a country</option>
              {countryOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <button type="submit" className="button small">Save</button>
            <button type="button" onClick={() => setIsEditingCountry(false)} className="button small secondary">Cancel</button>
          </form>
        ) : (
          <>
            {country || 'Unknown'}
            <button onClick={() => setIsEditingCountry(true)} className="button small">Edit</button>
          </>
        )}
      </div>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
    </div>
  );
}

export default UserInfoCard;