// components/ClickStatisticsCard.js

import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from '../axiosConfig';

function ClickStatisticsCard() {
  const [stats, setStats] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get('/api/user/click-stats');
        setStats(response.data);
      } catch (err) {
        console.error('Failed to fetch click statistics', err);
        setError('Failed to load statistics. Please try again later.');
      }
    };
    fetchStats();
  }, []);

  if (error) return <div className="account-card error">{error}</div>;
  if (!stats) return <div className="account-card">Loading statistics...</div>;

  return (
    <div className="account-card">
      <h2>Click Statistics</h2>
      <p><strong>Total Clicks:</strong> {stats.totalClicks || 0}</p>
      <p><strong>Average Clicks per Day:</strong> {stats.averageClicksPerDay || '0.00'}</p>
      <p><strong>Days Since First Click:</strong> {stats.daysSinceFirstClick || 0}</p>
      <p><strong>Longest Click Streak:</strong> {stats.longestStreak || 'N/A'}</p>
    </div>
  );
}

export default ClickStatisticsCard;