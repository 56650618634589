import React, { useState, useEffect } from 'react';
import { checkPersonalMilestone, checkGlobalMilestone, celebratePersonalMilestone, celebrateGlobalMilestone } from '../utils/MilestoneCelebrations';
import MilestoneCelebration from './MilestoneCelebrations';

function MilestoneHandler({ globalCount, userTotalClicks }) {
  const [currentMilestone, setCurrentMilestone] = useState(null);

  useEffect(() => {
    const personalMilestone = checkPersonalMilestone(userTotalClicks);
    const globalMilestone = checkGlobalMilestone(globalCount);

    if (personalMilestone) {
      setCurrentMilestone(personalMilestone);
      celebratePersonalMilestone(personalMilestone);
    } else if (globalMilestone) {
      setCurrentMilestone(globalMilestone);
      celebrateGlobalMilestone(globalMilestone);
    }
  }, [globalCount, userTotalClicks]);

  const handleCelebrationComplete = () => {
    setCurrentMilestone(null);
  };

  return currentMilestone ? (
    <MilestoneCelebration 
      milestone={currentMilestone} 
      onComplete={handleCelebrationComplete}
    />
  ) : null;
}

export default MilestoneHandler;