import React, { useCallback, useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import useSyncManager from '../hooks/useSyncManager';
import ClickButton from './ClickButton';
import GlobalCounter from './GlobalCounter';
import PersonalCounter from './PersonalCounter';
import MilestoneHandler from './MilestoneHandler';
import UsersRanking from './UsersRanking';
import CountryRanking from './CountryRanking';
import './ClickCounter.css';

function ClickCounter() {
  const { user } = useAuth();
  const {
    globalCount,
    userTotalClicks,
    handleClick,
    error,
    isLoading,
    fetchInitialData
  } = useSyncManager();

  const [isClicking, setIsClicking] = useState(false);
  const [localLoading, setLocalLoading] = useState(true);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  
  useEffect(() => {
    if (!isLoading) {
      setLocalLoading(false);
    }
  }, [isLoading]);

  // useEffect(() => {
  //   if (user) {
  //     refetch();
  //   }
  // }, [user, refetch]);

  const handleButtonClick = useCallback(() => {
    setIsClicking(true);
    handleClick();
    setTimeout(() => setIsClicking(false), 100);
  }, [handleClick]);

  if (localLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="click-section">
        <div className="counter-container">
          <GlobalCounter count={globalCount} />
        </div>
        <ClickButton onClick={handleClick} />
        <PersonalCounter count={userTotalClicks} isAnonymous={!user} />
        <MilestoneHandler
          globalCount={globalCount}
          userTotalClicks={userTotalClicks}
        />
    </div>
  );
}

export default ClickCounter;