import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import { useAuth } from '../contexts/AuthContext';
import './AuthForms.css';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();
  const [passwordStrength, setPasswordStrength] = useState('');
  const [usernameError, setUsernameError] = useState('');

  const validateUsername = (username) => {
    if (username.length > 20) {
      setUsernameError('Username cannot be more than 20 characters long');
      return false;
    }
    setUsernameError('');
    return true;
  };

  const checkPasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]+/)) strength++;
    if (password.match(/[A-Z]+/)) strength++;
    if (password.match(/[0-9]+/)) strength++;
    if (password.match(/[$@#&!]+/)) strength++;

    switch (strength) {
      case 0:
      case 1:
        setPasswordStrength('Weak');
        break;
      case 2:
      case 3:
        setPasswordStrength('Medium');
        break;
      case 4:
        setPasswordStrength('Strong');
        break;
      case 5:
        setPasswordStrength('Very Strong');
        break;
      default:
        setPasswordStrength('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const anonymousId = localStorage.getItem('anonymousId');
      const response = await axios.post('/api/auth/signup', { email, password, username, anonymousId });
      console.log('Signup response:', response.data);
      
      await login(response.data.token, response.data.user);
      localStorage.removeItem('anonymousId');
      
      navigate('/');
    } catch (err) {
      console.error('Signup error:', err);
      setError(err.response?.data?.message || 'An error occurred during sign up');
    }
  };

  const getPasswordStrengthDots = () => {
    switch (passwordStrength) {
      case 'Weak':
        return <span className="password-dots weak">⏺⏺</span>;
      case 'Medium':
        return <span className="password-dots medium">⏺⏺⏺</span>;
      case 'Strong':
        return <span className="password-dots strong">⏺⏺⏺⏺</span>;
      case 'Very Strong':
        return <span className="password-dots very-strong">⏺⏺⏺⏺⏺</span>;
      default:
        return null;
    }
  };

  return (
    <div className="auth-form-container">
      <h2>Sign Up</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit} className="auth-form">
        <div className="form-group">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              validateUsername(e.target.value);
            }}
            placeholder="Username"
            required
            maxLength={20}
          />
          {usernameError && <p className="error-message">{usernameError}</p>}
        </div>
        <div className="form-group">
          <input
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              checkPasswordStrength(e.target.value);
            }}
            placeholder="Password"
            required
          />
        </div>
        {passwordStrength && (
          <div className="password-strength">
            <span>{passwordStrength} Password</span> {getPasswordStrengthDots()}
          </div>
        )}
        <button type="submit" className="auth-button">Sign Up</button>
      </form>
      <p>
        Already have an account? <Link to="/login">Login</Link>
      </p>
    </div>
  );
}

export default SignUp;

