import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactCountryFlag from "react-country-flag";
import countryList from 'react-select-country-list';
import './CountryRanking.css';

const API_URL = 'https://api.clicktoinfinity.com' || process.env.REACT_APP_API_URL || 'http://localhost:5001';

function CountryRanking() {
  const [data, setData] = useState([]);
  const countryOptions = countryList().getData();
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/clicks/countryRanking`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching country ranking:', error);
        setError('Failed to load country ranking. Please try again later.');
      }
    };
    fetchData();
  
    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, []);
  
  if (error) {
    return <div className="error-message">{error}</div>;
  }

  const getCountryCode = (countryName) => {
    const customCountryCodeMap = {
      'Israel': 'IL',
      'United States': 'US',
      'United Kingdom': 'GB',
      'Canada': 'CA',
      'Australia': 'AU',
      'Germany': 'DE',
      'France': 'FR',
      'Japan': 'JP',
      'China': 'CN',
      'India': 'IN',
      'Brazil': 'BR',
      'England': 'GB-ENG',
    };

    if (customCountryCodeMap[countryName]) {
      return customCountryCodeMap[countryName];
    }

    const country = countryOptions.find(c => c.label === countryName);
    return country ? country.value : 'UN'; // 'UN' for unknown
  };

  return (
    <div className="country-ranking">
      <h2>Country Ranking</h2>
      <table className="country-ranking-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Country</th>
            <th>Clicks</th>
          </tr>
        </thead>
        <tbody>
          {data.map((country, index) => (
            <tr key={country.country}>
              <td>{index + 1}</td>
              <td>
                <ReactCountryFlag 
                  countryCode={getCountryCode(country.country)} 
                  svg 
                  style={{
                    width: '1.5em',
                    height: '1.5em',
                    marginRight: '0.5em'
                  }}
                />
                {country.country}
              </td>
              <td>{country.clicks}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CountryRanking;