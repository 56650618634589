import countries from 'i18n-iso-countries';
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const countryMapping = {
  // Add any specific mappings here
  "United States": "United States of America",
  "USA": "United States of America",
  "UK": "United Kingdom",
  // Add more as needed
};

export function normalizeCountryName(countryName) {
  if (!countryName) return 'Unknown';

  // Check if there's a direct mapping
  if (countryMapping[countryName]) {
    return countryMapping[countryName];
  }

  // Try to find the country code
  const countryCode = countries.getAlpha2Code(countryName, 'en');
  if (countryCode) {
    return countries.getName(countryCode, 'en');
  }

  // If no match found, return the original name
  return countryName;
}

export function getCountryList() {
  return Object.entries(countries.getNames("en")).map(([code, name]) => ({
    value: name,
    label: name
  }));
}