
import React, { useEffect, useState } from 'react';
import './MilestoneCelebrations.css';

const personalMilestones = [175, 180, 185, 190, 195, 200];
const globalMilestones = [600, 605, 610, 1000000, 10000000];

export const checkPersonalMilestone = (clicks) => {
  return personalMilestones.find(milestone => clicks === milestone);
};

export const checkGlobalMilestone = (clicks) => {
  return globalMilestones.find(milestone => clicks === milestone);
};

export const celebratePersonalMilestone = (milestone) => {
  console.log(`Celebrating personal milestone: ${milestone} clicks!`);
};

export const celebrateGlobalMilestone = (milestone) => {
  console.log(`Celebrating global milestone: ${milestone} clicks!`);
};

export const playClickSound = () => {
  // Implement click sound logic here
  console.log('Playing click sound');
};
const celebrationConfig = {
  emojis: ['🎉', '🎊', '🥳', '🚀', '💯', '🏆', '👏', '🙌', '🔥', '⭐', '🌟', '💥', '🌈', '🦄', '🍾', '🥂'],
  colors: ['#FFD700', '#FF6347', '#4CAF50', '#3498db', '#9b59b6', '#1abc9c', '#e74c3c', '#2ecc71'],
  emojiCount: 90,
  minEmojiSize: 1,
  maxEmojiSize: 5,
  minDistance: 100,
  maxDistance: 600,
  animationDuration: 4000,
  fadeOutStart: 3000,
  numberFontSize: '30vw',
};

const MilestoneCelebration = ({ milestone, onComplete }) => {
  const [showCelebration, setShowCelebration] = useState(true);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [color] = useState(celebrationConfig.colors[Math.floor(Math.random() * celebrationConfig.colors.length)]);
  const [selectedEmojis] = useState(Array.from(
    { length: celebrationConfig.emojiCount }, 
    () => celebrationConfig.emojis[Math.floor(Math.random() * celebrationConfig.emojis.length)]
  ));

  useEffect(() => {
    const fadeOutTimer = setTimeout(() => {
      setIsFadingOut(true);
    }, celebrationConfig.fadeOutStart);

    const completionTimer = setTimeout(() => {
      setShowCelebration(false);
      onComplete();
    }, celebrationConfig.animationDuration);

    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(completionTimer);
    };
  }, [onComplete]);

  return (
    <div className={`milestone-celebration ${showCelebration ? 'show' : ''} ${isFadingOut ? 'fade-out' : ''}`}>
      <div 
        className="milestone-number"
        style={{ color, fontSize: celebrationConfig.numberFontSize }}
      >
        {milestone}
      </div>
      {selectedEmojis.map((emoji, index) => (
        <div 
          key={index} 
          className="emoji-splash"
          style={{
            '--delay': `${Math.random() * 0.5}s`,
            '--duration': `${2 + Math.random()}s`,
            '--angle': `${Math.random() * 360}deg`,
            '--distance': `${celebrationConfig.minDistance + Math.random() * (celebrationConfig.maxDistance - celebrationConfig.minDistance)}px`,
            '--size': `${celebrationConfig.minEmojiSize + Math.random() * (celebrationConfig.maxEmojiSize - celebrationConfig.minEmojiSize)}rem`
          }}
        >
          {emoji}
        </div>
      ))}
    </div>
  );
};

export default MilestoneCelebration;