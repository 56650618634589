// components/Account.js

import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import UserInfoCard from './UserInfoCard';
import ClickStatisticsCard from './ClickStatisticsCard';
import SettingsCard from './SettingsCard';
import DeleteAccountCard from './DeleteAccountCard';
import './Account.css';

function Account() {
  const { user, loading } = useAuth();

  if (loading) {
    return <div className="loading-container">Loading user information...</div>;
  }

  if (!user) {
    return <div className="error-container">Please log in to view your account.</div>;
  }

  return (
    <div className="account-page">
      <h1>Your Account</h1>
      <div className="account-grid">
        <div className="account-column">
          <UserInfoCard />
          <SettingsCard />
        </div>
        <div className="account-column">
          <ClickStatisticsCard />
          <DeleteAccountCard />
        </div>
      </div>
    </div>
  );
}

export default Account;