import { useState, useEffect, useCallback, useRef } from 'react';
import axios from '../axiosConfig';
import { API_URL } from '../config';
import { useAuth } from '../contexts/AuthContext';

function useSyncManager() {
  const { user, anonymousId, isAuthenticated, fetchUserInfo, logout, ensureAnonymousId } = useAuth();
  const [globalCount, setGlobalCount] = useState(() => parseInt(localStorage.getItem('globalCount') || '0', 10));
  const [userTotalClicks, setUserTotalClicks] = useState(0);
  const [anonymousTotalClicks, setAnonymousTotalClicks] = useState(() => parseInt(localStorage.getItem('anonymousTotalClicks') || '0', 10));
  const [error, setError] = useState(null);
  const [userCountry, setUserCountry] = useState(() => localStorage.getItem('userCountry') || '');
  const [nickname, setNickname] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const localClicksRef = useRef(0);
  const syncIntervalRef = useRef(null);
  const isSyncingRef = useRef(false);
  const targetClicksRef = useRef(0);

  const smoothUpdate = useCallback((targetClicks) => {
    targetClicksRef.current = targetClicks;
    const updateInterval = setInterval(() => {
      setUserTotalClicks((prev) => {
        if (prev === targetClicksRef.current) {
          clearInterval(updateInterval);
          return prev;
        }
        return prev + Math.sign(targetClicksRef.current - prev);
      });
    }, 50); // Update every 50ms
  }, []);

  const resetPersonalCount = useCallback(() => {
    setUserTotalClicks(0);
    setAnonymousTotalClicks(0);
    localClicksRef.current = 0;
    targetClicksRef.current = 0;
  }, []);

  const fetchInitialData = useCallback(async () => {
    setIsLoading(true);
    try {
      await fetchUserInfo();
      
      let response;
      if (isAuthenticated) {
        response = await axios.get(`${API_URL}/api/auth/user`);
        smoothUpdate(response.data.totalClicks || 0);
        setNickname(response.data.username || '');
      } else {
        const currentAnonymousId = ensureAnonymousId();
        response = await axios.get(`${API_URL}/api/clicks/initial-data`, {
          params: { anonymousId: currentAnonymousId, country: userCountry }
        });
        setAnonymousTotalClicks(response.data.userTotalClicks || 0);
      }
      
      if (response.data.globalCount !== undefined) {
        setGlobalCount(response.data.globalCount);
        localStorage.setItem('globalCount', response.data.globalCount.toString());
      }
      
      if (response.data.userCountry || response.data.country) {
        setUserCountry(response.data.userCountry || response.data.country);
        localStorage.setItem('userCountry', response.data.userCountry || response.data.country);
      }
      
      if (!isAuthenticated && response.data.userTotalClicks !== undefined) {
        localStorage.setItem('anonymousTotalClicks', response.data.userTotalClicks.toString());
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
      setError('Failed to load initial data. Please try refreshing the page.');
    } finally {
      setIsLoading(false);
    }
  }, [isAuthenticated, fetchUserInfo, ensureAnonymousId, userCountry, smoothUpdate]);
  

  const syncWithBackend = useCallback(async () => {
    if (isSyncingRef.current || localClicksRef.current === 0) return;

    isSyncingRef.current = true;
    const clicksToSync = localClicksRef.current;
    localClicksRef.current = 0;

    try {
      const data = {
        clicks: clicksToSync,
        userId: isAuthenticated ? user?.id : undefined,
        anonymousId: isAuthenticated ? undefined : ensureAnonymousId(),
        country: userCountry
      };

      const response = await axios.post(`${API_URL}/api/clicks/sync`, data);

      if (response.data.globalCount !== undefined) {
        setGlobalCount(response.data.globalCount);
      }
      
      if (response.data.userTotalClicks !== undefined) {
        if (isAuthenticated) {
          smoothUpdate(response.data.userTotalClicks);
        } else {
          setAnonymousTotalClicks(response.data.userTotalClicks);
          localStorage.setItem('anonymousTotalClicks', response.data.userTotalClicks.toString());
        }
      }

    } catch (error) {
      console.error('Error syncing with backend:', error);
      localClicksRef.current += clicksToSync;
      setError('Failed to sync clicks. Please try again later.');
    } finally {
      isSyncingRef.current = false;
    }
  }, [isAuthenticated, user, userCountry, ensureAnonymousId, smoothUpdate]);

  const handleClick = useCallback(() => {
    if (isAuthenticated) {
      setUserTotalClicks(prevClicks => prevClicks + 1);
    } else {
      setAnonymousTotalClicks(prevClicks => prevClicks + 1);
    }
    setGlobalCount(prevCount => prevCount + 1);
    localClicksRef.current += 1;

    if (!isSyncingRef.current) {
      syncWithBackend();
    }
  }, [isAuthenticated, syncWithBackend]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData, isAuthenticated]);

  useEffect(() => {
    syncIntervalRef.current = setInterval(() => syncWithBackend(), 1000);

    return () => {
      if (syncIntervalRef.current) {
        clearInterval(syncIntervalRef.current);
      }
    };
  }, [syncWithBackend]);

  useEffect(() => {
    if (!isAuthenticated) {
      resetPersonalCount();
    }
  }, [isAuthenticated, resetPersonalCount]);

  const getCurrentTotalClicks = useCallback(() => {
    return isAuthenticated ? userTotalClicks : anonymousTotalClicks;
  }, [isAuthenticated, userTotalClicks, anonymousTotalClicks]);

  return {
    globalCount,
    userTotalClicks: getCurrentTotalClicks(),
    handleClick,
    error,
    userCountry,
    nickname,
    isLoading,
    fetchInitialData,
    resetPersonalCount,
  };
}

export default useSyncManager;