// src/axiosConfig.js

import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5001',
  withCredentials: true
});

export const externalAxios = axios.create({
    withCredentials: false
  });
  
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token has expired or is invalid
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      // Optionally, redirect to login page or update app state
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default instance;