import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Account from './components/Account';
import SignUp from './components/SignUp';
import ClickCounter from './components/ClickCounter';
import CountryRanking from './components/CountryRanking';
import UsersRanking from './components/UsersRanking';
import RankingTabs from './components/RankingTabs';
import OAuthCallback from './components/OAuthCallback';
import './App.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Navbar />
          <div className="content">
            <Routes>
              <Route path="/" element={
                <div className="main-container">
                  <div className="left-column">
                    <CountryRanking />
                  </div>
                  <div className="center-column">
                    <ClickCounter />
                  </div>
                  <div className="right-column">
                    <UsersRanking />
                  </div>
                  <div className="mobile-rankings">
                    <RankingTabs />
                  </div>
                </div>
              } />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/account" element={<Account />} />
              <Route path="/oauth-callback" element={<OAuthCallback />} />
            </Routes>
          </div>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;