import React from 'react';

function ClickButton({ onClick, isClicking }) {
  return (
    <button 
      className={`click-button ${isClicking ? 'clicking' : ''}`} 
      onClick={onClick}
    >
      CLICK!
    </button>
  );
}

export default ClickButton;