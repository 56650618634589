// src/components/PersonalCounter.js

import React from 'react';
import './PersonalCounter.css';

function PersonalCounter({ count }) {
  const formattedCount = count ? count.toLocaleString() : '0';

  return (
    <div className="personal-counter">
      <h3 className="personal-counter-title">Your Clicks</h3>
      <div className="personal-counter-display">
        {formattedCount}
      </div>
    </div>
  );
}

export default PersonalCounter;