import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anonymousId, setAnonymousId] = useState(() => localStorage.getItem('anonymousId'));
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const ensureAnonymousId = useCallback(() => {
    if (!anonymousId) {
      const newAnonymousId = Math.random().toString(36).substr(2, 9);
      setAnonymousId(newAnonymousId);
      localStorage.setItem('anonymousId', newAnonymousId);
      return newAnonymousId;
    }
    return anonymousId;
  }, [anonymousId]);

  const login = useCallback((token, userData) => {
    localStorage.setItem('token', token);
    setUser(userData);
    setIsAuthenticated(true);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setLoading(false);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('userTotalClicks');
    setUser(null);
    setIsAuthenticated(false);
    delete axios.defaults.headers.common['Authorization'];
    ensureAnonymousId();
    setLoading(false);
    navigate('/');
  }, [ensureAnonymousId, navigate]);

  const fetchUserInfo = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.get('/api/auth/user');
        setUser(response.data);
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Error fetching user info:', error);
        logout();
      }
    } else {
      setUser(null);
      setIsAuthenticated(false);
      ensureAnonymousId();
    }
    setLoading(false);
  }, [logout, ensureAnonymousId]);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [logout]);

  const value = {
    user,
    anonymousId,
    isAuthenticated,
    login,
    logout,
    fetchUserInfo,
    loading,
    ensureAnonymousId
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}