import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import useSyncManager from '../hooks/useSyncManager';
import './Navbar.css';

function Navbar() {
  const { user, logout } = useAuth();
  const [yCount, setYCount] = useState(1);
  const minY = 1;
  const maxY = 19;
  const { resetPersonalCount } = useSyncManager();
  const [isIncreasing, setIsIncreasing] = useState(true);
  const [animatingIndex, setAnimatingIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleAccountClick = () => {
    if (user) {
      navigate('/account');
    } else {
      navigate('/signup');
    }
  };

  const handleLogout = () => {
    logout();
    resetPersonalCount();
    navigate('/');
  };


  const handleLogoClick = () => {
    setYCount((prevCount) => {
      if (isIncreasing) {
        if ((isMobile && prevCount === 9) || (!isMobile && prevCount === maxY)) {
          setIsIncreasing(false);
          setAnimatingIndex(prevCount - 1);
          return prevCount - 1;
        }
        setAnimatingIndex(prevCount);
        return prevCount + 1;
      } else {
        if (prevCount === minY) {
          setIsIncreasing(true);
          setAnimatingIndex(prevCount);
          return prevCount + 1;
        }
        setAnimatingIndex(prevCount - 1);
        return prevCount - 1;
      }
    });
  };

  useEffect(() => {
    if (animatingIndex !== null) {
      const timer = setTimeout(() => setAnimatingIndex(null), 300);
      return () => clearTimeout(timer);
    }
  }, [animatingIndex]);

  return (
    <nav className="navbar">
      {isMobile && (
        <ul className="navbar-nav">
          <li className="nav-item">
            <button onClick={handleAccountClick} className="nav-link">
              {user ? 'Account' : 'Sign Up'}
            </button>
          </li>
          {user ? (
            <li className="nav-item">
              <button onClick={handleLogout} className="nav-link logout-button">Logout</button>
            </li>
          ) : (
            <li className="nav-item">
              <Link to="/login" className="nav-link">Login</Link>
            </li>
          )}
        </ul>
      )}
      <div className="navbar-content">
        <Link to="/" className="logo" onClick={handleLogoClick}>
          <span className="logo-click">CLICK</span>
          <span className="logo-to">TO</span>
          <span className="logo-infinity">
            INFINIT
            {[...Array(yCount)].map((_, index) => (
              <span 
                key={index} 
                className={`logo-y ${animatingIndex === index ? 'animating' : ''}`}
              >
                Y
              </span>
            ))}
          </span>
        </Link>
        {!isMobile && (
          <ul className="navbar-nav">
            <li className="nav-item">
              <button onClick={handleAccountClick} className="nav-link">
                {user ? 'Account' : 'Sign Up'}
              </button>
            </li>
            {user ? (
              <li className="nav-item">
                <button onClick={handleLogout} className="nav-link logout-button">Logout</button>
              </li>
            ) : (
              <li className="nav-item">
                <Link to="/login" className="nav-link">Login</Link>
              </li>
            )}
          </ul>
        )}
      </div>
    </nav>
  );
}

export default Navbar;