// src/utils/MilestoneCelebrations.js

const personalMilestones = [135, 140, 145, 150, 155, 160];
const globalMilestones = [1000, 10000, 100000, 1000000, 10000000];

export const checkPersonalMilestone = (clicks) => {
  return personalMilestones.find(milestone => clicks === milestone);
};

export const checkGlobalMilestone = (clicks) => {
  return globalMilestones.find(milestone => clicks === milestone);
};

export const celebratePersonalMilestone = (milestone) => {
  // Implementation for personal milestone celebration
  console.log(`Celebrating personal milestone: ${milestone} clicks!`);
  // TODO: Add pop-up, animation, or sound effect logic here
};

export const celebrateGlobalMilestone = (milestone) => {
  // Implementation for global milestone celebration
  console.log(`Celebrating global milestone: ${milestone} clicks!`);
  // TODO: Add more elaborate celebration logic here (e.g., fireworks animation, sound effects)
};

export const playClickSound = () => {
  // TODO: Implement sound effect for each click
};

export const playMilestoneSound = (isGlobal) => {
  // TODO: Implement different sounds for personal vs global milestones
};