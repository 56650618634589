// src/components/GlobalCounter.js

import React from 'react';
import './GlobalCounter.css';

function GlobalCounter({ count }) {
  const formattedCount = count ? count.toLocaleString() : '0';
  const digits = formattedCount.split('').filter(char => char !== ',');

  return (
    <div className="global-counter">
      <h2 className="global-counter-title">Global Clicks</h2>
      <div className="global-counter-display">
        {digits.map((digit, index) => (
          <div key={index} className="global-counter-digit">
            {digit}
          </div>
        ))}
      </div>
    </div>
  );
}

export default GlobalCounter;